import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"
import { cn } from "@/lib/utils"

const Slider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={cn(
            "relative flex w-full touch-none select-none items-center",
            "before:content-[''] before:absolute before:inset-0 before:-top-4 before:-bottom-4",
            className
        )}
        {...props}
    >
        <SliderPrimitive.Track className="relative h-2 w-full grow overflow-visible rounded-full bg-white dark:bg-gray-600">
            <div
                className={cn(
                    "absolute h-full",
                    props.value && props.value[0] < 50 ? "bg-amber-200 dark:bg-amber-200" : "hidden"
                )}
                style={{
                    right: '50%',
                    width: props.value && props.value[0] < 50 ? `${50 - props.value[0]}%` : '0%'
                }}
            />
            <div
                className={cn(
                    "absolute h-full",
                    props.value && props.value[0] > 50 ? "bg-blue-100 dark:bg-blue-300" : "hidden"
                )}
                style={{
                    left: '50%',
                    width: props.value && props.value[0] > 50 ? `${props.value[0] - 50}%` : '0%'
                }}
            />
            <div className="absolute inset-0 flex justify-between px-[2px]">
                {[...Array(7)].map((_, i) => (
                    <div
                        key={i}
                        className="w-0.5 h-4 -mt-1 bg-white/50"
                        style={{
                            transform: i === 0 ? 'translateX(0)' : i === 6 ? 'translateX(-100%)' : 'translateX(-50%)'
                        }}
                    />
                ))}
            </div>
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-white bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
))

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider } 