import React, { useState, useCallback, useEffect } from 'react';
import { AnalysisResult } from '../types';
import { getTypeDescription } from '../data/typeDescriptionDetails';
import FeedbackPopup from './FeedbackPopup';
import ThankYouPage from './ThankYouPage';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Button } from "./ui/button";
import { api } from '../utils/api';

interface ResultsProps {
  results: AnalysisResult | null;
  onFeedbackSubmit: (feedback: string) => void;
  userData: {
    name: string;
    sessionId: string;
    accessCode?: string;
  };
}

const Results: React.FC<ResultsProps> = ({ results, onFeedbackSubmit, userData }) => {
  const [showInstructions, setShowInstructions] = useState(true);
  const [selectedText, setSelectedText] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const maxRetries = 3;
  const retryDelay = 1000; // 1 second

  useEffect(() => {
    const sendResultsTelemetry = async (retryCount = 0) => {
      if (!results || !userData.name || !userData.sessionId) {
        console.warn('Missing required data for results telemetry:', {
          results: !!results,
          name: !!userData.name,
          sessionId: !!userData.sessionId
        });
        return;
      }

      try {
        console.log('Sending results telemetry...');
        await api.results({
          sessionId: userData.sessionId,
          name: userData.name,
          // Convert only the type field which needs conversion
          type: results.type.toString(),
          // String fields - no conversion needed
          qpref_d: results.QPrefD,
          qpref_l: results.QPrefL,
          qstr_d: results.QStrD,
          qstr_l: results.QStrL,
          mpol_de: results.MPolDe,
          mpol_le: results.MPolLe,
          q: results.Q,
          // Numeric fields - convert to integers
          s1_dogs: Math.round(results.s1dogs),
          s1_cats: Math.round(results.s1cats),
          s1_wolves: Math.round(results.s1wolves),
          s1_lions: Math.round(results.s1lions),
          s2_dogs: Math.round(results.s2dogs),
          s2_cats: Math.round(results.s2cats),
          s2_wolves: Math.round(results.s2wolves),
          s2_lions: Math.round(results.s2lions),
          s3_kangaroo: Math.round(results.s3kangaroo),
          s3_giraffe: Math.round(results.s3giraffe),
          s3_horses: Math.round(results.s3horses),
          s3_unicorns: Math.round(results.s3unicorns),
          // String fields - no conversion needed
          pref_pair_de: results.PrefPairDe,
          pref_pair_le: results.PrefPairLe,
          // Numeric fields - convert to integers
          pref_pair_de_con_val: Math.round(results.PrefPairDeConVal),
          pref_pair_le_con_val: Math.round(results.PrefPairLeConVal),
          // Boolean fields - no conversion needed
          pref_pair_de_con_dir: results.PrefPairDeConDir,
          pref_pair_le_con_dir: results.PrefPairLeConDir,
          // String fields - no conversion needed
          str_pair_etlo: results.StrPairEtlo,
          str_pair_sein: results.StrPairSein,
          str_pair_etlo_con_var: results.StrPairEtloConVar,
          str_pair_sein_con_var: results.StrPairSeinConVar
        });
        console.log('Results telemetry sent successfully');
      } catch (error) {
        console.error('Failed to send results telemetry:', error);

        // Only attempt retries for network-related errors
        const isNetworkError = !navigator.onLine || (error instanceof Error && error.message.includes('Network Error'));
        const shouldRetry = isNetworkError && retryCount < maxRetries;

        // Store failed telemetry locally
        const telemetryData = {
          type: 'results',
          data: {
            sessionId: userData.sessionId,
            name: userData.name,
            results
          },
          timestamp: new Date().toISOString()
        };

        // Check if the same entry already exists
        const pendingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const isDuplicate = pendingLogs.some((log: any) =>
          log.type === 'results' &&
          log.data.sessionId === userData.sessionId &&
          log.data.name === userData.name
        );

        // Only add if it's not a duplicate
        if (!isDuplicate) {
          pendingLogs.push(telemetryData);
          localStorage.setItem('surveyLogs', JSON.stringify(pendingLogs));
          console.log('Failed telemetry stored in localStorage');
        } else {
          console.log('Skipping duplicate telemetry entry');
        }

        // Retry logic
        if (shouldRetry) {
          console.log(`Retrying results telemetry (attempt ${retryCount + 1}/${maxRetries})...`);
          setTimeout(() => sendResultsTelemetry(retryCount + 1), retryDelay * (retryCount + 1));
        } else if (!isNetworkError) {
          console.log('Not retrying due to non-network error');
        } else {
          console.error('Max retries reached for results telemetry');
          console.log('Results will be synced when connection is restored');
        }
      }
    };

    sendResultsTelemetry();
  }, [results, userData, maxRetries, retryDelay]);

  // Add network reconnection detection to sync stored telemetry
  useEffect(() => {
    // Check for stored telemetry on mount
    const syncStoredTelemetry = async () => {
      const pendingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');

      if (pendingLogs.length === 0) return;

      console.log(`Found ${pendingLogs.length} pending telemetry logs to sync`);

      // Process stored telemetry logs
      const remainingLogs = [];
      let successCount = 0;

      for (const log of pendingLogs) {
        if (log.type === 'results') {
          try {
            console.log('Attempting to send stored results telemetry');
            await api.results({
              sessionId: log.data.sessionId,
              name: log.data.name,
              type: log.data.results.type.toString(),
              qpref_d: log.data.results.QPrefD,
              qpref_l: log.data.results.QPrefL,
              qstr_d: log.data.results.QStrD,
              qstr_l: log.data.results.QStrL,
              mpol_de: log.data.results.MPolDe,
              mpol_le: log.data.results.MPolLe,
              q: log.data.results.Q,
              s1_dogs: Math.round(log.data.results.s1dogs),
              s1_cats: Math.round(log.data.results.s1cats),
              s1_wolves: Math.round(log.data.results.s1wolves),
              s1_lions: Math.round(log.data.results.s1lions),
              s2_dogs: Math.round(log.data.results.s2dogs),
              s2_cats: Math.round(log.data.results.s2cats),
              s2_wolves: Math.round(log.data.results.s2wolves),
              s2_lions: Math.round(log.data.results.s2lions),
              s3_kangaroo: Math.round(log.data.results.s3kangaroo),
              s3_giraffe: Math.round(log.data.results.s3giraffe),
              s3_horses: Math.round(log.data.results.s3horses),
              s3_unicorns: Math.round(log.data.results.s3unicorns),
              pref_pair_de: log.data.results.PrefPairDe,
              pref_pair_le: log.data.results.PrefPairLe,
              pref_pair_de_con_val: Math.round(log.data.results.PrefPairDeConVal),
              pref_pair_le_con_val: Math.round(log.data.results.PrefPairLeConVal),
              pref_pair_de_con_dir: log.data.results.PrefPairDeConDir,
              pref_pair_le_con_dir: log.data.results.PrefPairLeConDir,
              str_pair_etlo: log.data.results.StrPairEtlo,
              str_pair_sein: log.data.results.StrPairSein,
              str_pair_etlo_con_var: log.data.results.StrPairEtloConVar,
              str_pair_sein_con_var: log.data.results.StrPairSeinConVar
            });
            console.log('Successfully sent stored telemetry');
            successCount++;
            // Don't add this log to remainingLogs, effectively removing it from localStorage
          } catch (error) {
            console.error('Failed to send stored telemetry, keeping in queue:', error);
            remainingLogs.push(log);
          }
        } else if (log.type === 'surveyEnded') {
          try {
            console.log('Attempting to send stored surveyEnded telemetry');
            await api.surveyEnded({
              sessionId: log.data.sessionId,
              name: log.data.name
            });
            console.log('Successfully sent stored surveyEnded telemetry');
            successCount++;
            // Don't add this log to remainingLogs, effectively removing it from localStorage
          } catch (error) {
            console.error('Failed to send stored surveyEnded telemetry, keeping in queue:', error);
            remainingLogs.push(log);
          }
        } else {
          // Keep other log types (if any)
          remainingLogs.push(log);
        }
      }

      // Update storage with remaining logs
      if (remainingLogs.length > 0) {
        localStorage.setItem('surveyLogs', JSON.stringify(remainingLogs));
        console.log(`${successCount} logs synced, ${remainingLogs.length} remaining`);
      } else {
        localStorage.removeItem('surveyLogs');
        console.log('All telemetry logs synced successfully');
      }
    };

    // Initial check on component mount
    syncStoredTelemetry();

    // Set up online event listener to sync when connection is restored
    const handleOnline = () => {
      console.log('Network connection restored, syncing stored telemetry');
      syncStoredTelemetry();
    };

    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.target as HTMLSpanElement;
    if (target.className.includes('feedback-sentence')) {
      setSelectedText(target.textContent?.trim() || '');
      setShowPopup(true);
    }
  };

  //rendering results
  const renderSentences = (text: string | undefined) => {
    if (!text) return null;

    return text.split(/\n\s*\n/).map((paragraph, pIndex) => (
      <div key={`p-${pIndex}`} className="mb-4">
        {paragraph.split(/(?<=\.)\s+/).map((sentence, sIndex) => (
          <span
            key={`${pIndex}-${sIndex}`}
            className="feedback-sentence cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 px-1 rounded"
            onClick={handleClick}
          >
            {sentence.trim()}{sIndex < paragraph.split(/(?<=\.)\s+/).length - 1 ? ' ' : ''}
          </span>
        ))}
      </div>
    ));
  };

  const handleClosePopup = useCallback(() => {
    // Clear the selection when closing the popup
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
    setShowPopup(false);
  }, []);

  const handleFeedbackSubmit = async (feedback: {
    selectedText: string;
    comments: string;
    agree: boolean;
    disagree: boolean;
  }) => {
    console.log('Attempting to send feedback telemetry:', {
      sessionId: userData.sessionId,
      name: userData.name || '',
      feedback
    });

    try {
      await api.resultsFeedback({
        sessionId: userData.sessionId,
        name: userData.name || '',
        highlightedText: feedback.selectedText,
        comment: feedback.comments,
        feedbackType: feedback.agree ? 'agree' : 'disagree'
      });
    } catch (error) {
      console.error('Failed to send results feedback telemetry:', error);
    }

    onFeedbackSubmit(JSON.stringify(feedback));
    setShowPopup(false);
  };

  const handleSurveyEnd = async () => {
    if (!userData.name || !userData.sessionId) {
      console.error('Missing required user data for survey end');
      return;
    }

    console.log('Attempting to send survey ended telemetry:', {
      sessionId: userData.sessionId,
      name: userData.name
    });

    try {
      // Check for and attempt to send any pending telemetry before completing
      const pendingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
      if (pendingLogs.length > 0) {
        console.log(`Syncing ${pendingLogs.length} pending telemetry logs before survey completion`);
        // Only show this message if there are pending logs
      }

      await api.surveyEnded({
        sessionId: userData.sessionId,
        name: userData.name
      });
      setSurveyCompleted(true);
    } catch (error) {
      console.error('Failed to send survey ended telemetry:', error);

      // Store the survey ended event in localStorage for later sync
      const pendingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
      pendingLogs.push({
        type: 'surveyEnded',
        data: {
          sessionId: userData.sessionId,
          name: userData.name
        },
        timestamp: new Date().toISOString()
      });
      localStorage.setItem('surveyLogs', JSON.stringify(pendingLogs));

      // Still complete the survey for the user
      setSurveyCompleted(true);
    }
  };

  if (surveyCompleted) {
    return <ThankYouPage />;
  }

  if (!results) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-4xl text-center">
        <Card>
          <CardContent>
            <p className="text-foreground">No results available</p>
          </CardContent>
        </Card>
      </div>
    );
  }

  const detailedDescription = getTypeDescription(results.type);

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      {showInstructions && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <Card className="max-w-md w-full p-6 bg-white dark:bg-gray-800">
            <h3 className="text-lg font-semibold mb-4">Welcome to Your Results</h3>
            <p className="mb-6">
              The following are your survey results. Please click (or tap) any sentences
              which you have strong positive or negative feedback on.
            </p>
            <Button
              className="w-full"
              onClick={() => setShowInstructions(false)}
            >
              OK
            </Button>
          </Card>
        </div>
      )}

      {detailedDescription && (
        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-2xl">{detailedDescription.title}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="prose max-w-none dark:prose-invert mb-6">
              {detailedDescription?.detailedDescription && renderSentences(detailedDescription.detailedDescription)}
            </div>

            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Growth Areas</h3>
              <ul className="list-disc pl-5 space-y-2">
                {detailedDescription?.characteristics?.map((char, index) => (
                  <li key={index} className="text-foreground">
                    {renderSentences(char)}
                  </li>
                ))}
              </ul>
            </div>

            <Card className="bg-primary/5">
              <CardHeader>
                <CardTitle className="text-primary">Challenging Growth Areas</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc pl-5 space-y-2">
                  {detailedDescription?.challenges?.map((challenge, index) => (
                    <li key={index} className="text-foreground">
                      {renderSentences(challenge)}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </CardContent>
        </Card>
      )}

      {
        //commenting out intermediate values. Delete later. 
        /* <Card> 
          <CardHeader>
            <CardTitle>Your Animal Profile Results</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6">
              <div>
                <h3 className="text-xl font-semibold mb-2">Final Results</h3>
                <div className="grid grid-cols-2 gap-2">
                  <p><strong>Type:</strong> {type}</p>
                  <p><strong>QPrefD:</strong> {QPrefD}</p>
                  <p><strong>QPrefL:</strong> {QPrefL}</p>
                  <p><strong>QStrD:</strong> {QStrD}</p>
                  <p><strong>QStrL:</strong> {QStrL}</p>
                  <p><strong>MPolDe:</strong> {MPolDe}</p>
                  <p><strong>MPolLe:</strong> {MPolLe}</p>
                  <p><strong>Q:</strong> {Q}</p>
                </div>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Intermediate Values</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 1</h4>
                    <p><strong>s1Dogs:</strong> {s1dogs}</p>
                    <p><strong>s1Cats:</strong> {s1cats}</p>
                    <p><strong>s1Wolves:</strong> {s1wolves}</p>
                    <p><strong>s1Lions:</strong> {s1lions}</p>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 2</h4>
                    <p><strong>s2Dogs:</strong> {s2dogs}</p>
                    <p><strong>s2Cats:</strong> {s2cats}</p>
                    <p><strong>s2Wolves:</strong> {s2wolves}</p>
                    <p><strong>s2Lions:</strong> {s2lions}</p>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold mt-2">Section 3</h4>
                    <p><strong>s3Kangaroo:</strong> {s3kangaroo}</p>
                    <p><strong>s3Giraffe:</strong> {s3giraffe}</p>
                    <p><strong>s3Horses:</strong> {s3horses}</p>
                    <p><strong>s3Unicorns:</strong> {s3unicorns}</p>
                  </div>
                </div>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Preference Pairs</h3>
                <p><strong>PrefPairDe:</strong> {PrefPairDe}</p>
                <p><strong>PrefPairLe:</strong> {PrefPairLe}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Confidence Values</h3>
                <p><strong>PrefPairDeConVal:</strong> {PrefPairDeConVal}</p>
                <p><strong>PrefPairLeConVal:</strong> {PrefPairLeConVal}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Directional Accuracy</h3>
                <p><strong>PrefPairDeConDir:</strong> {PrefPairDeConDir ? 'True' : 'False'}</p>
                <p><strong>PrefPairLeConDir:</strong> {PrefPairLeConDir ? 'True' : 'False'}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Strength Pairs</h3>
                <p><strong>StrPairEtlo:</strong> {StrPairEtlo}</p>
                <p><strong>StrPairSein:</strong> {StrPairSein}</p>
              </div>
  
              <div>
                <h3 className="text-xl font-semibold mb-2">Strength Pair Confidence</h3>
                <p><strong>StrPairEtloConVar:</strong> {StrPairEtloConVar}</p>
                <p><strong>StrPairSeinConVar:</strong> {StrPairSeinConVar}</p>
              </div>
            </div>
          </CardContent>
        </Card> */
      }

      <div className="mt-12 mb-16 text-center">
        <Button onClick={handleSurveyEnd} size="lg" className="font-bold text-white">
          End Survey
        </Button>
      </div>

      {showPopup && (
        <FeedbackPopup
          selectedText={selectedText}
          onClose={handleClosePopup}
          onSubmit={handleFeedbackSubmit}
        />
      )}
    </div>
  );
};

export default Results;