import * as React from "react"
import { cn } from "@/lib/utils"
import { Slider } from "@/components/ui/slider"
import { SpectrumValue } from "@/types"

interface CustomSliderProps {
    value: SpectrumValue | null
    onChange: (value: SpectrumValue) => void
    onCommit?: (value: SpectrumValue) => void
    disabled?: boolean
    isReset?: boolean
    className?: string
    locked?: boolean
    previousPartAnswered?: boolean
    section?: number
    part3?: boolean
    activeSlider?: number
    feedbackRef?: React.RefObject<HTMLDivElement>
}

const spectrumValues: SpectrumValue[] = [
    "Only A",
    "Usually A",
    "Generally A",
    "Both",
    "Generally B",
    "Usually B",
    "Only B"
]

// Calculate step positions for snapping
const STEP_SIZE = 100 / (spectrumValues.length - 1)
const STEPS = spectrumValues.map((_, index) => index * STEP_SIZE)

function valueToSliderPosition(value: SpectrumValue | null): number {
    if (!value) return 50 // Default to middle
    const index = spectrumValues.indexOf(value)
    return index * STEP_SIZE
}

function sliderPositionToValue(position: number): SpectrumValue {
    // Find the closest step
    const closestStep = STEPS.reduce((prev, curr) =>
        Math.abs(curr - position) < Math.abs(prev - position) ? curr : prev
    )
    const index = Math.round(closestStep / STEP_SIZE)
    return spectrumValues[index]
}

export function CustomSlider({
    value,
    onChange,
    onCommit,
    disabled,
    isReset,
    className,
    locked,
    previousPartAnswered,
    section,
    part3,
    activeSlider,
    feedbackRef
}: CustomSliderProps) {
    const [hasInteracted, setHasInteracted] = React.useState(false)
    const [localValue, setLocalValue] = React.useState<number[]>([50])
    const [feedbackSectionRevealed, setFeedbackSectionRevealed] = React.useState(false)

    React.useEffect(() => {
        if (value) {
            setHasInteracted(true)
            setLocalValue([valueToSliderPosition(value)])
        }
    }, [value])

    React.useEffect(() => {
        if (isReset) {
            setHasInteracted(false)
            setLocalValue([50])
            setFeedbackSectionRevealed(false)
        }
    }, [isReset])

    // Effect to handle feedback section visibility
    React.useEffect(() => {
        if (part3 && activeSlider === 3) {
            setFeedbackSectionRevealed(true)
        }
    }, [part3, activeSlider])

    const handleValueChange = React.useCallback(
        (newValue: number[]) => {
            if (locked) return
            setLocalValue(newValue)
            setHasInteracted(true)
            onChange(sliderPositionToValue(newValue[0]))
            // If the value is exactly 50 (middle position), trigger the commit
            if (newValue[0] === 50) {
                onCommit?.(sliderPositionToValue(newValue[0]))
            }
        },
        [locked, onChange, onCommit]
    )

    const handleValueCommit = React.useCallback(
        (newValue: number[]) => {
            if (locked) return
            const snappedValue = STEPS.reduce((prev, curr) =>
                Math.abs(curr - newValue[0]) < Math.abs(prev - newValue[0]) ? curr : prev
            )
            setLocalValue([snappedValue])
            const committedValue = sliderPositionToValue(snappedValue)
            onChange(committedValue)
            onCommit?.(committedValue)
        },
        [locked, onChange, onCommit]
    )

    // Add click handler for the thumb
    const handleThumbClick = React.useCallback(() => {
        if (locked || disabled) return
        if (!hasInteracted) {
            setHasInteracted(true)
            onChange("Both")
            onCommit?.("Both")
        }
    }, [locked, disabled, hasInteracted, onChange, onCommit])

    const currentValue = localValue

    const getSliderLabels = () => {
        if (section === 2 || section === 2.1) {
            return (
                <span>
                    Much Stronger at <span className="text-amber-200 dark:text-amber-200">A</span>
                </span>
            )
        }
        return (
            <span>
                Strongly Prefer <span className="text-amber-200 dark:text-amber-200">A</span>
            </span>
        )
    }

    const getSliderLabelsRight = () => {
        if (section === 2 || section === 2.1) {
            return (
                <span>
                    Much Stronger at <span className="text-blue-100 dark:text-blue-300">B</span>
                </span>
            )
        }
        return (
            <span>
                Strongly Prefer <span className="text-blue-100 dark:text-blue-300">B</span>
            </span>
        )
    }

    return (
        <div className={cn("relative pt-6 pb-2", className)} role="group" aria-label="Preference Slider">
            <div className="relative">
                {/* Dynamic Label positioned relative to thumb */}
                <div
                    className="absolute -top-6 left-0 right-0 pointer-events-none"
                    style={{
                        width: 'calc(100% - 12px)',
                        marginLeft: '6px',
                    }}
                >
                    <span
                        className={cn(
                            "absolute text-sm font-medium transition-colors duration-200 whitespace-nowrap transform -translate-x-1/2",
                            "text-white",
                            (disabled || locked) && "opacity-30"
                        )}
                        style={{
                            left: `${currentValue[0]}%`,
                            transition: hasInteracted ? 'none' : 'left 0.2s ease-out'
                        }}
                    >
                        {hasInteracted ? (
                            <span>
                                {currentValue[0] === 50 ? (
                                    "Both"
                                ) : (
                                    sliderPositionToValue(currentValue[0])
                                        .split(" ")
                                        .map((word, i, arr) => (
                                            <React.Fragment key={i}>
                                                {word === "A" ? (
                                                    <span className="text-amber-200 dark:text-amber-200">A</span>
                                                ) : word === "B" ? (
                                                    <span className="text-blue-100 dark:text-blue-300">B</span>
                                                ) : (
                                                    word
                                                )}
                                                {i < arr.length - 1 ? " " : ""}
                                            </React.Fragment>
                                        ))
                                )}
                            </span>
                        ) : (
                            "Select a position"
                        )}
                    </span>
                </div>
                <Slider
                    value={currentValue}
                    min={0}
                    max={100}
                    step={0.1}
                    onValueChange={handleValueChange}
                    onValueCommit={handleValueCommit}
                    disabled={disabled || locked}
                    className={cn(
                        "w-full transition-opacity duration-200",
                        previousPartAnswered && disabled && "opacity-30",
                        (disabled || locked) && "opacity-30 cursor-not-allowed",
                        locked && "opacity-30"
                    )}
                    aria-label="Preference Scale"
                    onClick={handleThumbClick}
                />
            </div>
            <div className={cn(
                "flex justify-between mt-2 text-sm text-muted-foreground",
                previousPartAnswered && disabled && "opacity-30",
                locked && "opacity-30"
            )}>
                <span>
                    {getSliderLabels()}
                </span>
                <span
                    className={cn(
                        (disabled || locked) && "opacity-30"
                    )}
                >
                    Neutral
                </span>
                <span>
                    {getSliderLabelsRight()}
                </span>
            </div>
            {locked && (
                <div className="absolute top-0 flex items-center justify-center pointer-events-none">
                    <div className="bg-background/80 backdrop-blur-[2px] px-0 py-0 rounded text-sm text-muted-foreground dark:text-red-200">
                        This value is locked to match your previous answer
                    </div>
                </div>
            )}
            {/* Optional Feedback Section */}
            <div
                ref={feedbackRef}
                className="transition-all duration-200"
                style={{
                    opacity: feedbackSectionRevealed ? 1 : 0.3,
                    pointerEvents: feedbackSectionRevealed ? 'auto' : 'none'
                }}
            >
            </div>
        </div>
    )
} 