export const VERSION = {
    buildNumber: '1.0.13',  // Semantic versioning: MAJOR.MINOR.PATCH
    buildDate: new Date().toISOString(),
    environment: import.meta.env.VITE_APP_ENV || 'production',
    get displayVersion() {
        // For staging, append -staging to the version number
        return this.environment === 'staging'
            ? `${this.buildNumber}-staging`
            : this.buildNumber
    }
}; 