import { SurveyQuestion } from "../types";
import { logger } from "../utils/logger";

// Section 1: Questions 1,2,5,6 only
export const section1Questions: SurveyQuestion[] = [
    {
        id: 1,
        text: "1. You attend a networking lunch and are assigned to sit at a table of 10 people whom you do not know. Which social approach do you prefer?",
        optionA:
            "Someone suggests an ice breaker where people introduce themselves by sharing a fun fact and their current company. Everyone is encouraged to engage in the conversation.",
        optionB:
            "Someone beside you introduces themself. They ask you about yourself and seem to want to get to you know more. The table consists of several of these one-on-one conversations.",
        onSelect: (option: "A" | "B") => {
            logger.log(`1.${option.toLowerCase()}`);
        }
    },
    {
        id: 2,
        text: "2. You work with a non-profit program and are assisting a coworker in developing guidelines for interviewing volunteers. Which coworker would you prefer to work with?",
        optionA:
            "Someone who adopts a generalized approach to 'cut the red tape,' thus allowing the interviewers to be more flexibile and adaptable. Increased autonomy will result in a more efficient recruiting process.",
        optionB:
            "Someone who adopts a detailed and thorough approach to ensure accuracy, thus increasing recruiting efficiency. The entire process will be documented and used as a reference by others.",
        onSelect: (option: "A" | "B") => {
            logger.log(`2.${option.toLowerCase()}`);
        }
    },
    {
        id: 3,
        text: "3. You are invited to a friend's wedding. Which type of social wedding atmosphere would you prefer?",
        optionA:
            "Vibrant music and boisterous, lighthearted, funny toasts that allow guests to share in the couple's celebration. You enjoy casual mingling and may keep it brief to interact with more people.",
        optionB:
            "Intimate, engaging atmosphere and sincere toasts. You enjoy one-on-one conversations where a deeper connection may exist. You may keep it brief with people you don't know well.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.${option.toLowerCase()}`);
        }
    },
    {
        id: 4,
        text: "4. A team of students is seeking your advice on a group presentation. Which group do you prefer to advise?",
        optionA:
            "A group where each person wants to be assigned a specific task and process they must complete thoroughly. They don't want to miss any details, though might end up doing some extra work.",
        optionB:
            "A group who wants help identifying the top factors that may result in the most sizeable impact. Members can complete the work how they see fit, but this approach might miss some important details.",
        onSelect: (option: "A" | "B") => {
            logger.log(`4.${option.toLowerCase()}`);
        }
    },
];

// Section 2: Questions 9,10,13,14,17,18,21,22 only
export const section2Questions: SurveyQuestion[] = [
    {
        id: 5,
        text: "5. Which of these skills comes most naturally to you?",
        optionA:
            "Understanding and adjusting the emotional atmosphere of a group.\n\nEx. Bringing excitement to a group at a birthday party as you tend to be able to read the room and adapt accordingly.",
        optionB:
            "Understanding and adjusting highly complicated logical structures.\n\nEx. Learning the principles behind income taxes as it follows complex, yet repeatable patterns.",
        onSelect: (option: "A" | "B") => {
            logger.log(`5.${option.toLowerCase()}`);
        }
    },
    {
        id: 6,
        text: "6. Which of these skills comes most naturally to you?",
        optionA:
            "Understanding and adjusting the emotional distance of your individual relationships.\n\nEx. Expressing a specific opinion to your best friend in order to feel closer.",
        optionB:
            "Understanding and adjusting specific components in a system to increase efficiency.\n\nEx. Preparing ingredients and utensils before you start cooking to streamline cooking and cleaning.",
        onSelect: (option: "A" | "B") => {
            logger.log(`6.${option.toLowerCase()}`);
        }
    },
    {
        id: 7,
        text: "7. Which of these skills comes most naturally to you?",
        optionA:
            "Communicating with other people through adjusting your tone-of-voice and facial expressions.\n\nEx. Knowing when to use a soft or firm tone in order to encourage a team that is falling behind.",
        optionB:
            "Communicating with other people through consistent explanations and systematic logical principles.\n\nEx. Explaining the concepts and rules of a game to a group of people who haven't played it before.",
        onSelect: (option: "A" | "B") => {
            logger.log(`7.${option.toLowerCase()}`);
        }
    },
    {
        id: 8,
        text: "8. Which of these skills comes most naturally to you?",
        optionA:
            "Interpreting your opinions and how those opinions make you feel.\n\nEx. Considering how a house in a neighborhood makes you feel to determine an ideal house to buy.",
        optionB:
            "Interpreting data points and individual facts.\n\nEx. Reviewing an article about neighborhood amenities, Home Owner Association rules, and taxes to determine an ideal house to buy.",
        onSelect: (option: "A" | "B") => {
            logger.log(`8.${option.toLowerCase()}`);
        }
    },
    {
        id: 9,
        text: "9. Which type of advice are you more naturally skilled at providing?",
        optionA:
            "How to join social groups and engage in group activities.\n\nEx. Advising a friend on some rules-of-thumb to make connections at an upcoming wedding they plan to attend, such as scanning the room to see which groups are interesting before joining their conversation.",
        optionB:
            "How to understand the frameworks and principles of logistics in activities.\n\nEx. Advising a friend on how to plan travel to an upcoming wedding they plan to attend, such as providing a travel checklist, a budget, and ensuring they have the appropriate identification.",
        onSelect: (option: "A" | "B") => {
            logger.log(`9.${option.toLowerCase()}`);
        }
    },
    {
        id: 10,
        text: "10. Which type of advice are you more naturally skilled at providing?",
        optionA:
            "How to develop a close relationship in order to make it more valuable.\n\nEx. Advising a coworker on how to pull back from a friendship that has been challenging for them.",
        optionB:
            "How to identify and compare business or economic opportunities.\n\nEx. Advising a coworker on how to compare different 401k investment options.",
        onSelect: (option: "A" | "B") => {
            logger.log(`10.${option.toLowerCase()}`);
        }
    },
    {
        id: 11,
        text: "11. Which type of advice are you more naturally skilled at providing?",
        optionA:
            "Managing social groups and the expectations of its members.\n\nEx. Advising a friend on how to reduce social friction in a challenging friend group.",
        optionB:
            "Managing personal finances by following rules and principles.\n\nEx. Advising a friend on how to improve their financial wellness by using strategies of budgeting, spending, and saving.",
        onSelect: (option: "A" | "B") => {
            logger.log(`11.${option.toLowerCase()}`);
        }
    },
    {
        id: 12,
        text: "12. Which type of advice are you more naturally skilled at providing?",
        optionA:
            "Managing the nuances and situational details of a close relationship.\n\nEx. Advising a family member on how to use their personality traits to better connect with another family member.",
        optionB:
            "Managing personal finances through leveraging unique opportunities.\n\nEx. Advising a family member on how to to take advantage of a recent change in the housing market.",
        onSelect: (option: "A" | "B") => {
            logger.log(`12.${option.toLowerCase()}`);
        }
    },
];

// Section 2.1: Tie breaker questions
export const section2_1Questions: Record<string, SurveyQuestion> = {
    "2_1_1": {
        id: 2.11,
        text: "The following best describes my approach to helping a friend who is feeling down:",
        optionA: "I rely on my engaging demeanor as a proactive way to encourage and uplift my friend. Knowing the specifics does not necessarily change my approach and may be a waste of energy.", //Cats + 1
        optionB: "I often ask specifics around their mood and am willing to take considerable time listening and comforting my friend. I am skilled in providing subtle advice in a supportive way.", //Dogs + 1
        onSelect: (option: "A" | "B") => {
            logger.log(`2.11.${option.toLowerCase()}`);
        }
    },
    "2_1_2": {
        id: 2.12,
        text: "When faced with a task that may take a few hours to complete, the following best describes my natural mode of operation:",
        optionA: "I take a step back to evaluate the big picture, ensuring I understand the overall structure, organization, and goals before diving into specific tasks. Even for routine tasks, I focus on the overall plan before considering the components as this ensures everything fits together properly.", //Lions + 1
        optionB: "I consider a few of the most important details of my tasks, or complete a few of the highest priority tasks first, then structure my plan around those points. I often act quickly for familiar tasks. I focus on the 'why' behind the task details to better understand the big picture. ", //Wolves + 1
        onSelect: (option: "A" | "B") => {
            logger.log(`2.12.${option.toLowerCase()}`);
        }
    },
    "2_1_3": {
        id: 2.13,
        text: "The following best describes my approach to helping a friend who is feeling down:",
        optionA: "I am skilled at asking the right questions and feeling out how I may or may not relate to their challenges. I prefer this approach over adjusting my expressions to alter their mood, as that may be a waste of energy.", //Dogs + 1
        optionB: "I take the time to listen and learn the details of their situation. Sometimes I will adjust my own emotional state or tailor my expressions to alter their mood if I believe it is necessary.", //Cats + 1
        onSelect: (option: "A" | "B") => {
            logger.log(`2.13.${option.toLowerCase()}`);
        }
    },
    "2_1_4": {
        id: 2.14,
        text: "When faced with a task that may take a few hours to complete, the following best describes my natural mode of operation:",
        optionA: "Quickly evaluate the work I need to accomplish as I often prioritize efficiency in completing the tasks. I can figure out the overall structure and organization of my task as needed, but this may be wasted effort.", //Wolves + 1
        optionB: "Carefully evaluate the work I need to accomplish, and I often find it useful to take time to consider my task within a broader context. I am skilled at doing both, but more so in stepping back to see how the parts make up the whole.", //Lions + 1
        onSelect: (option: "A" | "B") => {
            logger.log(`2.14.${option.toLowerCase()}`);
        }
    },
};

// Section 3: Branching questions
export const section3Questions: Record<number, SurveyQuestion> = {
    3.1: {
        id: 3.1,
        text: "13. To increase group engagement and motivation, which approach do you prefer?",
        optionA:
            "Engage with (or become) the most influential person in a team to get buy-in for a specific goal. This ultimately helps the entire team align toward a central purpose.",
        optionB:
            "Assist in aligning the group's mood with the occasion, keeping event hospitality in mind. Ensure everyone's comfort by having snacks, a conducive environment or thoughtful, personalized touches for the group.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.1.${option.toLowerCase()}`);
        }
    },
    3.2: {
        id: 3.2,
        text: "14. When joining a large team, which approach you prefer?",
        optionA:
            "Apply learned or intuitive rules on how to behave depending on who you are around. Attach yourself to the key players within a group in order to influence their motivations and goals.",
        optionB:
            "Follow appropriate etiquette and maintain awareness of whom you are engaging with. Be flexible to their needs and provide the team whatever is needed, such as a positive attitude, decision-making, or some kind words.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.2.${option.toLowerCase()}`);
        }
    },
    3.3: {
        id: 3.3,
        text: "13. When organizing a brainstorming session to solve a complex problem, which approach do you prefer?",
        optionA:
            "Allow the experts to speak first thus reducing unnecessary speculation toward solving the problem. Any additional ideas from the group can be pre-screened and brought to the experts beforehand.",
        optionB:
            "Encourage group discussion to allow many perspectives. Rushing the discussion may result in important considerations being left out. Consider how the perspectives and ideas fit together into the big picture.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.3.${option.toLowerCase()}`);
        }
    },
    3.4: {
        id: 3.4,
        text: "14. In order to provide a vision for a team to follow, which approach do you prefer?",
        optionA:
            "Gather all necessary data points to understand what is possible, entirely reasonable, and immediately actionable. Involve experts. Document a detailed and specific plan with defined milestones.",
        optionB:
            "Ensure the plan has enough flexibility to integrate multiple paths forward. Bounce ideas off others, including experts. Document many ideas and refine along the way.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.4.${option.toLowerCase()}`);
        }
    },
    3.5: {
        id: 3.5,
        text: "13. Which individual would you prefer to work with?",
        optionA:
            "Someone who is interested in hearing an idea or two but really focused on taking immediate action on these; if the idea isn't realistic or actionable they will question it.",
        optionB:
            "Someone who is interested in hearing many ideas in order to gain perspective but isn't interested in necessarily applying them directly; the whole point of ideas are to increase perspective.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.5.${option.toLowerCase()}`);
        }
    },
    3.6: {
        id: 3.6,
        text: "14. What is your approach to deciding where to eat with a group of indecisive friends?",
        optionA:
            "I would play the role of mediator and facilitate the group to make a decision; the longer we wait, the more hungry we get.",
        optionB:
            "I would offer perspective in hopes to rally support around someone's idea. The more perspective, the better choice we can make together.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.6.${option.toLowerCase()}`);
        }
    },
    3.7: {
        id: 3.7,
        text: "13. Which approach do you prefer for household organization and cleanliness?",
        optionA:
            "Home efficiency is important to me. I appreciate quick and easy access to commonly-used items. The aesthetics of how this is accomplished is a lower priority than the organization itself. I rarely spend time cleaning or organizing unless I know guests are coming over.",
        optionB:
            "My home is my sanctuary and I keep it organized and tidy to give me peace of mind. Displaying meaningful experiences such as vacation photographs, trinkets and mementos, or favorite board games are the difference between a house and a home.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.7.${option.toLowerCase()}`);
        }
    },
    3.8: {
        id: 3.8,
        text: "14. To gain self-awareness and chart your personal growth path, which approach do you prefer?",
        optionA:
            "The steps I take today toward immediate milestones will dictate long-term future outcomes and play an important role in who I will become. This planning is enjoyable.",
        optionB:
            "My lineage, family history, and the path taken by family members all play an important role in who I am and who I will become. These topics are fascinating.",
        onSelect: (option: "A" | "B") => {
            logger.log(`3.8.${option.toLowerCase()}`);
        }
    },
}; 