import React, { useState, useEffect } from "react";
import { scrollToTop } from "../utils/scroll";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";
import { VERSION } from '../config/version';
import { config } from '../config/environments';
import { api } from '../utils/api';

interface LandingPageProps {
  onSubmit: (data: UserData) => void;
  sessionId: string;
}

interface UserData {
  name: string;
  email: string;
  accessCode: string;
  age?: number;
  gender?: string;
  occupation?: string;
  maritalStatus?: string;
  sessionId?: string;
}

const LandingPage: React.FC<LandingPageProps> = ({ onSubmit, sessionId }) => {
  const VALID_ACCESS_CODES = ["fcfnf2024", "fcfnf2025"];

  const [formData, setFormData] = useState<UserData>({
    name: "",
    email: "",
    accessCode: ""
    // age: 0,
    // gender: "",
    // occupation: "",
    // maritalStatus: "",
  });

  const [accessError, setAccessError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === 'accessCode') {
      setAccessError("");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setAccessError("");
    setEmailError("");

    const trimmedName = formData.name.trim();
    const trimmedEmail = formData.email.trim();

    if (!trimmedName) {
      setAccessError("Please enter your name");
      await sendSurveyStartedTelemetry(false, 'MISSING_FIELD_NAME');
      return;
    }

    if (!trimmedEmail) {
      setEmailError("Please enter your email");
      await sendSurveyStartedTelemetry(false, 'MISSING_FIELD_EMAIL');
      return;
    }

    if (!VALID_ACCESS_CODES.includes(formData.accessCode.toLowerCase())) {
      setAccessError("Invalid access code. Please try again.");
      await sendSurveyStartedTelemetry(false, 'INVALID_ACCESS_CODE');
      return;
    }

    await sendSurveyStartedTelemetry(true, 'SUCCESS');
    scrollToTop();
    onSubmit({
      ...formData,
      name: trimmedName,
      email: trimmedEmail,
      accessCode: formData.accessCode.toLowerCase(),
      sessionId
    });
  };

  const sendSurveyStartedTelemetry = async (started: boolean, reason: string) => {
    try {
      await api.surveyStarted({
        sessionId,
        name: formData.name.trim(),
        email: formData.email.trim(),
        accessCode: formData.accessCode,
        started,
        reason,
        startTime: new Date().toISOString()
      });
    } catch (error) {
      console.error('Failed to send survey entered telemetry:', error);
    }
  };

  useEffect(() => {
    const sendSessionStartedTelemetry = async () => {
      if (!config.telemetryEnabled) return;

      try {
        console.log('Sending session started with version:', VERSION.displayVersion);

        const browserMetadata = {
          userAgent: navigator.userAgent,
          language: navigator.language,
          platform: navigator.platform,
          screenResolution: `${window.screen.width}x${window.screen.height}`,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        const result = await api.sessionStarted({
          sessionId,
          browserMetadata,
          buildNumber: VERSION.displayVersion,
          buildDate: VERSION.buildDate,
          environment: VERSION.environment
        });

        console.log('Session started response:', result);
      } catch (error) {
        console.error('Failed to send session started telemetry:', error);
      }
    };

    sendSessionStartedTelemetry();
  }, [sessionId]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-background px-1">
      <Card className="w-full max-w-md mx-0">
        <CardHeader className="px-2 sm:px-4 pt-4">
          <div className="flex justify-between items-center">
            <CardTitle className="text-2xl font-bold text-foreground">
              Welcome to the Four Coins Survey
            </CardTitle>

          </div>
        </CardHeader>
        <CardContent className="px-2 sm:px-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {emailError && (
                <p className="text-red-500 text-sm">{emailError}</p>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="accessCode">Access Code</Label>
              <Input
                type="text"
                id="accessCode"
                name="accessCode"
                value={formData.accessCode}
                onChange={handleChange}
                required
                style={{ textTransform: 'lowercase' }} // This will visually show lowercase

              />
              {accessError && (
                <p className="text-red-500 text-sm">{accessError}</p>
              )}
            </div>
            <Button type="submit" className="w-full font-bold text-white">
              Enter Survey
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default LandingPage;

