import React from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "./ui/card";
import { Button } from "./ui/button";

interface NdaPopupProps {
    onAccept: () => void;
    onClose: () => void;
}

const NdaPopup: React.FC<NdaPopupProps> = ({ onAccept, onClose }) => {
    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
            <Card className="max-w-2xl w-full bg-white dark:bg-gray-800 max-h-[90vh] overflow-y-auto">
                <CardHeader>
                    <CardTitle className="text-2xl font-bold">Non-Disclosure Agreement (NDA)</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4 text-foreground">
                    <p>
                        This agreement is between Quadra Solutions, LLC ("Us") and the Survey Participant ("You").
                        By participating in our software survey, you may see private information we want to keep confidential.
                    </p>

                    <div>
                        <h3 className="font-semibold mb-2">What You Agree To:</h3>
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Keep all information private and don't share it with anyone.</li>
                            <li>Use the information only for the survey.</li>
                            <li>Your responses to the survey are logged and securely retained for 180 days (data from this test is wiped after 180 days)</li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-2">What's Not Confidential:</h3>
                        <ul className="list-disc pl-5 space-y-1">
                            <li>Information that's already public.</li>
                            <li>Information you knew before the survey.</li>
                            <li>Information you figure out on your own without using ours.</li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-2">How Long This Lasts:</h3>
                        <p>For 1 year, or until the information becomes public.</p>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-2">What to Do When We Ask:</h3>
                        <p>Delete or return any confidential materials we've shared.</p>
                    </div>

                    <div>
                        <h3 className="font-semibold mb-2">Legal Stuff:</h3>
                        <p>This agreement follows the laws of the State of Texas.</p>
                    </div>

                    <p className="font-medium">By clicking "Accept" below, you agree to these terms.</p>
                </CardContent>
                <CardFooter className="flex justify-end gap-4 pt-6">
                    <Button onClick={onClose} className="bg-red-500 bg-opacity-75 font-bold text-white">
                        Decline
                    </Button>
                    <Button variant="outline" className="bg-green-600 bg-opacity-75 font-bold text-white" onClick={onAccept}>
                        Accept
                    </Button>
                </CardFooter>
            </Card>
        </div >
    );
};

export default NdaPopup; 