import React, { useState, useRef, useEffect } from "react";
import {
  SurveyQuestion,
  SurveyAnswer,
  AnalysisResult,
  UserData,
  SurveyData,
  SpectrumValue,
  AnswerSubmittedData
} from "../types";
import { analyzeResults } from "../utils/analysis";
import { logger } from "../utils/logger";
import Results from "./Results";
import { config } from '../config/environment';
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import { Input } from "./ui/input";
import { cn } from "../lib/utils";
import { api } from '../utils/api';
import { CustomSlider } from "./ui/custom-slider";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";
import { Info } from "lucide-react";
import {
  section1Questions,
  section2Questions,
  section2_1Questions,
  section3Questions
} from "./SurveyQuestions";

interface SurveyProps {
  userData: UserData & {
    name: string;
  };
  sessionId: string;
  onComplete?: (data: SurveyData) => void;
}

const Survey: React.FC<SurveyProps> = ({ userData, onComplete, sessionId }) => {
  const [currentSection, setCurrentSection] = useState(1);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
  const [results, setResults] = useState<AnalysisResult | null>(null);
  const [part1, setPart1] = useState<"A" | "B" | null>(null);
  const [part2, setPart2] = useState<SpectrumValue | null>(null);
  const [part3, setPart3] = useState<SpectrumValue | null>(null);
  const [currentSliderValue, setCurrentSliderValue] = useState<SpectrumValue | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [questionFeedback, setQuestionFeedback] = useState<"easy" | "difficult" | null>(null);
  const [difficultyFeedback, setDifficultyFeedback] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSendingTelemetry, setIsSendingTelemetry] = useState(false);
  const [isPart2Reset, setIsPart2Reset] = useState(true);
  const [isPart3Reset, setIsPart3Reset] = useState(true);
  const [isPart3Locked, setIsPart3Locked] = useState(false);
  const [previousPart3Value, setPreviousPart3Value] = useState<SpectrumValue | null>(null);
  const [activeSlider, setActiveSlider] = useState<2 | 3 | null>(null);
  const [scores, setScores] = useState({
    s1cats: 0,
    s1dogs: 0,
    s1wolves: 0,
    s1lions: 0,
    s2cats: 0,
    s2dogs: 0,
    s2wolves: 0,
    s2lions: 0,
    s3giraffe: 0,
    s3kangaroo: 0,
    s3horses: 0,
    s3unicorns: 0
  });
  const [showDebugPanel, setShowDebugPanel] = useState(false);

  // Add refs
  const feedbackRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const part1Ref = useRef<HTMLDivElement>(null);
  const part2Ref = useRef<HTMLDivElement>(null);
  const part3Ref = useRef<HTMLDivElement>(null);

  // Function to scroll to element if not fully visible
  const scrollToElement = (element: HTMLElement | null) => {
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleQuestionFeedback = (feedback: "easy" | "difficult") => {
    logger.log('Question feedback selected:', feedback);
    setQuestionFeedback(feedback);
    scrollToElement(nextButtonRef.current);
  };

  const handleAnswerPart2 = (value: SpectrumValue) => {
    logger.log('Part 2 selected:', value);
    setCurrentSliderValue(value);
    setPart2(value);
    setActiveSlider(2);
    if (isPart3Locked) {
      setPart3(value);
    }
    scrollToElement(part3Ref.current);
  };

  const handleAnswerPart3 = (value: SpectrumValue) => {
    if (isPart3Locked) return;
    logger.log('Part 3 selected:', value);
    setCurrentSliderValue(value);
    setPart3(value);
    setActiveSlider(3);
    setIsPart3Locked(false);
    scrollToElement(nextButtonRef.current);
  };

  const handlePart3Skip = () => {
    if (!part2) return;
    setPreviousPart3Value(part3);
    setPart3(part2);
    setActiveSlider(2);
    setIsPart3Locked(true);
    scrollToElement(nextButtonRef.current);
  };

  const handleUndoSkip = () => {
    setIsPart3Locked(false);
    if (previousPart3Value) {
      setPart3(previousPart3Value);
      setActiveSlider(3);
    } else {
      setPart3(null);
      setIsPart3Reset(true);
    }
  };

  // Reset states when question changes
  useEffect(() => {
    setIsPart2Reset(true);
    setIsPart3Reset(true);
    setIsPart3Locked(false);
    setPreviousPart3Value(null);
    setCurrentSliderValue(null);
  }, [currentQuestion, currentSection]);

  // Reset flags when values change
  useEffect(() => {
    if (part2) setIsPart2Reset(false);
  }, [part2]);

  useEffect(() => {
    if (part3) setIsPart3Reset(false);
  }, [part3]);

  const handleSubmit = async () => {
    try {
      // Validate required fields - currentQuestion can be 0 (first question)
      if (!part2 || !part3 || currentQuestion === undefined) {
        logger.warn('Submit blocked - missing required fields:', { part2, part3, currentQuestion });
        setMessage('Please complete all parts of the question before proceeding.');
        return;
      }

      const question = getCurrentQuestion();
      if (!question) {
        logger.warn('Submit blocked - invalid question:', { currentQuestion, currentSection });
        setMessage('Unable to proceed - invalid question state. Please refresh the page.');
        return;
      }

      setIsSubmitting(true);
      setIsSendingTelemetry(true);
      const timestamp = new Date().toISOString();

      // Create the answer object for the current state
      const answer: SurveyAnswer = {
        questionId: question.id,
        part1: part3 === "Both" ? "A" : part3.includes("A") ? "A" : "B",
        part2,
        part3,
        questionFeedback: questionFeedback || null,
        difficultyFeedback: difficultyFeedback || ''
      };

      // Create telemetry data
      const telemetryData: AnswerSubmittedData = {
        userData,
        sessionId,
        message_name: 'answer_submitted',
        questionId: String(question.id),
        part1: part3 === "Both" ? "A" : part3.includes("A") ? "A" : "B",
        part2,
        part3,
        questionFeedback: questionFeedback || null,
        difficultyFeedback: difficultyFeedback || '',
        timestamp,
        metadata: {
          version: {
            buildNumber: import.meta.env.VITE_BUILD_NUMBER || '0',
            buildDate: import.meta.env.VITE_BUILD_DATE || new Date().toISOString(),
            environment: (import.meta.env.MODE || 'development') as 'development' | 'production' | 'test',
            displayVersion: '1.0.0',
          },
          browser: window.navigator.userAgent,
          platform: window.navigator.platform,
          language: window.navigator.language,
          surveyStatus: 'in_progress',
          questionIndex: currentQuestion,
          totalQuestions: getTotalQuestions(),
        }
      };

      try {
        // Send telemetry first
        logger.log('Sending telemetry data:', telemetryData);
        await api.sendTelemetry(telemetryData);
        logger.log('Telemetry sent successfully');

        // If telemetry succeeds, handle navigation
        const newAnswers = [...answers, answer];

        // Update scores
        const partialResults = analyzeResults({ answers: newAnswers });
        setScores({
          s1cats: partialResults.s1cats,
          s1dogs: partialResults.s1dogs,
          s1wolves: partialResults.s1wolves,
          s1lions: partialResults.s1lions,
          s2cats: partialResults.s2cats,
          s2dogs: partialResults.s2dogs,
          s2wolves: partialResults.s2wolves,
          s2lions: partialResults.s2lions,
          s3giraffe: partialResults.s3giraffe,
          s3kangaroo: partialResults.s3kangaroo,
          s3horses: partialResults.s3horses,
          s3unicorns: partialResults.s3unicorns
        });

        await handleNext(newAnswers);
      } catch (telemetryError) {
        // If telemetry fails, store it locally and continue
        logger.error('Error sending telemetry:', telemetryError);
        const pendingLogs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        pendingLogs.push(telemetryData);
        localStorage.setItem('surveyLogs', JSON.stringify(pendingLogs));
        logger.log('Telemetry stored locally');

        // Continue with navigation despite telemetry error
        const newAnswers = [...answers, answer];
        await handleNext(newAnswers);
      }
    } catch (error) {
      logger.error('Error in handleSubmit:', error);
      if (error instanceof Error) {
        setMessage(`Failed to submit answer: ${error.message}. Please try again.`);
      } else {
        setMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
      setIsSendingTelemetry(false);
    }
  };

  const getCurrentSectionQuestions = () => {
    logger.log('Getting questions for section:', currentSection);

    if (currentSection === 1) {
      logger.log('Returning section 1 questions');
      return section1Questions;
    }
    if (currentSection === 2) {
      logger.log('Returning section 2 questions');
      return section2Questions;
    }

    if (currentSection === 2.1) {
      const partialResults = analyzeResults({ answers: answers.slice(0, 12) });
      const { QPrefD, QStrD } = partialResults;

      const mpoldeScore =
        Math.abs(partialResults.s2dogs) +
        Math.abs(partialResults.s2wolves) -
        (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

      if (mpoldeScore === 0) {
        if (QPrefD === "Feline" && QStrD === "Pets")
          return [section2_1Questions["2_1_1"]];
        if (QPrefD === "Feline" && QStrD === "Wild")
          return [section2_1Questions["2_1_2"]];
        if (QPrefD === "Canine" && QStrD === "Pets")
          return [section2_1Questions["2_1_3"]];
        if (QPrefD === "Canine" && QStrD === "Wild")
          return [section2_1Questions["2_1_4"]];
      }
      return [];
    }

    const partialResults = analyzeResults({ answers });
    const { QPrefD, QStrD } = partialResults;

    if (QPrefD === "Feline" && QStrD === "Pets")
      return [section3Questions[3.1], section3Questions[3.2]];
    if (QPrefD === "Feline" && QStrD === "Wild")
      return [section3Questions[3.3], section3Questions[3.4]];
    if (QPrefD === "Canine" && QStrD === "Pets")
      return [section3Questions[3.5], section3Questions[3.6]];
    if (QPrefD === "Canine" && QStrD === "Wild")
      return [section3Questions[3.7], section3Questions[3.8]];
    return [];
  };

  const getCurrentQuestion = (): SurveyQuestion => {
    const questions = getCurrentSectionQuestions();
    const question = questions[currentQuestion];
    logger.log('Current question:', question);
    return question;
  };

  const getTooltipText = (section: number, part: number) => {
    if (section === 1) {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, narrow gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else if (section === 2) {
      switch (part) {
        case 1:
          return "Skill means your ability to perform these actions with competence and completeness. A natural skill is one that does not require extra effort to do well";
        case 2:
          return "Wide gap means you are much more skilled in your selection over the other, narrow gap means the two choices are close in skill.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    } else {
      switch (part) {
        case 1:
          return "Assume both choices are equally viable. All your basic needs are met, and there are no consequences or constraints. Which would you prefer to do?";
        case 2:
          return "Wide gap means you strongly prefer your selection over the other, narrow gap means the two choices are close in preference.";
        case 3:
          return "Thinking back to when you were younger, like in your early 20's, would you have answered differently?";
      }
    }
    return "";
  };

  // Add useEffect for online/offline detection
  useEffect(() => {
    const checkPendingLogs = async () => {
      if (navigator.onLine) {
        const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
        if (logs.length > 0) {
          logger.log('Found pending logs, attempting to sync...');
          try {
            const response = await fetch('/api/telemetry/bulk', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ logs }),
            });

            const data = await response.json();
            if (!response.ok) {
              throw new Error(`API Error: ${data.error} - ${data.details}`);
            }

            localStorage.removeItem('surveyLogs');
            logger.log('Successfully synced local logs');
          } catch (error) {
            logger.error('Error syncing local logs:', error);
          }
        }
      }
    };

    // Function to handle coming online
    const handleOnline = () => {
      logger.log('Connection restored, attempting to sync...');
      checkPendingLogs();
    };

    // Add event listeners
    window.addEventListener('online', handleOnline);

    // Check for unsent logs on mount
    checkPendingLogs();

    // Cleanup
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  // Add keyboard shortcut listener
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Only enable debug panel in development mode
      if (import.meta.env.DEV) {
        // Ctrl + Alt + D to toggle debug panel
        if (event.ctrlKey && event.altKey && event.key === 'd') {
          setShowDebugPanel(prev => !prev);
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  // Update DEV_TOOLS to use our new toggle
  const DEV_TOOLS = showDebugPanel ? (
    <div className="fixed bottom-4 right-4 space-y-2">
      <div className="bg-black/80 text-white p-4 rounded shadow space-y-2 font-mono text-sm">
        <div className="font-bold border-b pb-1">Section 1 Scores:</div>
        <div>s1cats: {scores.s1cats}</div>
        <div>s1dogs: {scores.s1dogs}</div>
        <div>s1wolves: {scores.s1wolves}</div>
        <div>s1lions: {scores.s1lions}</div>
        <div className="font-bold border-b pb-1 mt-4">Section 2 Scores:</div>
        <div>s2cats: {scores.s2cats}</div>
        <div>s2dogs: {scores.s2dogs}</div>
        <div>s2wolves: {scores.s2wolves}</div>
        <div>s2lions: {scores.s2lions}</div>
        <div className="font-bold border-b pb-1 mt-4">Section 3 Scores:</div>
        <div>s3giraffe: {scores.s3giraffe}</div>
        <div>s3kangaroo: {scores.s3kangaroo}</div>
        <div>s3horses: {scores.s3horses}</div>
        <div>s3unicorns: {scores.s3unicorns}</div>
      </div>
      <button
        onClick={() => {
          const logs = JSON.parse(localStorage.getItem('surveyLogs') || '[]');
          logger.log('Current logs:', logs);
        }}
        className="bg-blue-100 text-white px-4 py-2 rounded shadow hover:bg-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600"
      >
        View Logs
      </button>
      <button
        onClick={() => {
          localStorage.removeItem('surveyLogs');
          logger.log('Logs cleared');
        }}
        className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600 block"
      >
        Clear Logs
      </button>
    </div>
  ) : null;

  useEffect(() => {
    try {
      logger.log('Survey mounted with userData:', userData);
      if (!userData?.name) {
        logger.error('Survey received invalid userData:', userData);
      }
    } catch (error) {
      console.error('Error in Survey mount:', error);
    }
  }, [userData]);

  const sendQuestionStartedTelemetry = async (questionId: string | number) => {
    try {
      await api.questionStarted({
        sessionId,
        name: userData.name,
        questionId: String(questionId)
      });
    } catch (error) {
      console.error('Failed to send question started telemetry:', error);
    }
  };

  // Update useEffect to send telemetry when question changes
  useEffect(() => {
    const question = getCurrentQuestion();
    if (question) {
      sendQuestionStartedTelemetry(question.id);
    }
  }, [currentSection, currentQuestion]);

  // Add CSS for text stroke
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = ``;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  function getTotalQuestions(): number {
    return section1Questions.length + section2Questions.length + Object.keys(section2_1Questions).length + Object.keys(section3Questions).length;
  }

  // Add this helper function to convert SpectrumValue to percentage
  const getSpectrumPercentages = (value: SpectrumValue | null): { a: number, b: number } => {
    if (!value) return { a: 50, b: 50 };

    const percentages = {
      "Only A": { a: 100, b: 0 },
      "Usually A": { a: 83, b: 17 },
      "Generally A": { a: 67, b: 33 },
      "Both": { a: 50, b: 50 },
      "Generally B": { a: 33, b: 67 },
      "Usually B": { a: 17, b: 83 },
      "Only B": { a: 0, b: 100 }
    };

    return percentages[value] || { a: 50, b: 50 };
  };

  if (results) {
    const userDataForResults = {
      name: userData?.name || '',
      sessionId: sessionId,
      accessCode: userData?.accessCode || ''
    };

    return <Results
      results={results}
      onFeedbackSubmit={() => { }}
      userData={userDataForResults}
    />;
  }

  const currentQuestions = getCurrentSectionQuestions();
  const question = getCurrentQuestion();
  const progress =
    ((currentSection - 1) * 100 +
      (currentQuestion + 1) * (100 / currentQuestions.length)) /
    3;

  const handleNext = async (currentAnswers: SurveyAnswer[]) => {
    logger.log('handleNext started with:', {
      currentAnswers,
      currentSection,
      currentQuestion,
      part1,
      part2,
      part3
    });

    setIsSubmitting(true);

    try {
      // Update answers state
      setAnswers(currentAnswers);
      logger.log('Updated answers state');

      // Reset form state
      setPart1(null);
      setPart2(null);
      setPart3(null);
      setQuestionFeedback(null);
      setDifficultyFeedback("");
      logger.log('Reset form state');

      // Navigation logic
      const currentQuestions = getCurrentSectionQuestions();
      logger.log('Current questions:', { currentQuestions, length: currentQuestions.length });

      // Check if we're at the end of a section
      const isLastQuestionInSection = currentQuestion === currentQuestions.length - 1;
      logger.log('Section status:', { isLastQuestionInSection, currentSection });

      if (!isLastQuestionInSection) {
        // Move to next question in current section
        logger.log('Moving to next question in current section');
        setCurrentQuestion(prev => prev + 1);
        setMessage(null);
      } else {
        // Handle section transitions
        logger.log('At end of section, determining next section');

        if (currentSection === 1) {
          // Moving from section 1 to section 2
          logger.log('Moving from section 1 to section 2');
          const partialResults = analyzeResults({
            answers: currentAnswers,
          });

          if (partialResults.QPrefD === "Unknown") {
            logger.log('Section 1 results unknown, resetting');
            setAnswers([]);
            setCurrentSection(1);
            setCurrentQuestion(0);
            setMessage("Please consider adjusting your gaps - in theory some of these should be narrow while others should be wide.");
          } else {
            logger.log('Moving to section 2');
            setCurrentSection(2);
            setCurrentQuestion(0);
          }
        } else if (currentSection === 2) {
          // Check if we need tie breaker
          logger.log('Processing section 2 transition');
          const partialResults = analyzeResults({
            answers: currentAnswers,
          });

          const mpoldeScore =
            Math.abs(partialResults.s2dogs) +
            Math.abs(partialResults.s2wolves) -
            (Math.abs(partialResults.s2cats) + Math.abs(partialResults.s2lions));

          logger.log('Section 2 mpoldeScore:', mpoldeScore);

          if (mpoldeScore === 0) {
            logger.log('Moving to section 2.1 (tie breaker)');
            setCurrentSection(2.1);
            setCurrentQuestion(0);
          } else {
            logger.log('Moving to section 3');
            setCurrentSection(3);
            setCurrentQuestion(0);
          }
        } else if (currentSection === 2.1) {
          logger.log('Moving from section 2.1 to section 3');
          setCurrentSection(3);
          setCurrentQuestion(0);
        } else if (currentSection === 3) {
          logger.log('Processing final section');
          const surveyData = {
            answers: currentAnswers,
          };
          const analysisResult = analyzeResults(surveyData);
          logger.log('Analysis result:', analysisResult);

          if (analysisResult.QPrefL === "Unknown") {
            logger.log('Section 3 results unknown, resetting section');
            const section1and2Answers = currentAnswers.slice(0, -2);
            setAnswers(section1and2Answers);
            setCurrentSection(3);
            setCurrentQuestion(0);
            setMessage("Please consider adjusting your answers for the final questions - try to identify which style you prefer more strongly.");
          } else {
            logger.log('Survey complete, setting results');
            setResults(analysisResult);
            onComplete?.(surveyData);
          }
        }
      }

      // Scroll to top after state updates are queued
      logger.log('Queueing scroll to top');
      setTimeout(() => {
        document.body.style.minHeight = '100vh';
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        });
        logger.log('Scroll complete');
        setIsSubmitting(false);
      }, 100);

    } catch (error) {
      logger.error('Error in handleNext:', error);
      setIsSubmitting(false);
      throw error;
    }
  };

  // Add this function to handle real-time slider updates
  const handleSliderMove = (value: SpectrumValue) => {
    setCurrentSliderValue(value);
  };

  const DEBUG_PANEL = config.enableDebugPanel ? (
    <div className="fixed top-4 left-4 bg-gray-800 p-4 rounded shadow text-white text-sm">
      <pre>
        {JSON.stringify(
          {
            section: currentSection,
            question: currentQuestion,
            part1,
            part2,
            part3,
            feedback: questionFeedback,
          },
          null,
          2
        )}
      </pre>
    </div>
  ) : null;

  return (
    <div className="min-h-screen flex items-center justify-center px-4 py-8 bg-gray-50 dark:bg-gray-900">
      {DEBUG_PANEL}
      {isSendingTelemetry && (
        <div className="fixed top-4 right-4 text-sm text-muted-foreground animate-pulse">
          Saving response...
        </div>
      )}
      <div className="w-full max-w-2xl">
        {message && (
          <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-20">
            <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 p-6 rounded-lg shadow-lg max-w-md mx-4">
              <p className="mb-4">{message}</p>
              <Button
                onClick={() => setMessage(null)}
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold"
                variant="default"
              >
                I Understand
              </Button>
            </div>
          </div>
        )}
        <Card className="w-full shadow-lg">
          <CardContent className="p-6">
            <div className="mb-6 relative">
              <Progress value={progress} className="w-full" />
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="bg-white dark:bg-gray-800 px-2 py-0.5 rounded-full text-sm font-medium text-gray-900 dark:text-gray-100 shadow-sm">
                  {Math.round(progress)}%
                </span>
              </div>
            </div>

            <div className="space-y-8">
              {/* Part 1 */}
              <div ref={part1Ref}>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center mb-4">
                        <h2 className="text-base font-semibold text-foreground dark:text-gray-200 mr-2">
                          {question.text}
                        </h2>
                        <Info className="w-4 h-4 text-muted-foreground" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{getTooltipText(currentSection, 1)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={cn(
                      "w-full text-left h-auto whitespace-pre-line p-4 justify-start transition-colors duration-200 relative overflow-hidden border rounded-md",
                      "dark:bg-gray-700 dark:text-gray-200"
                    )}
                  >
                    <div className="absolute left-0 top-0 bottom-0 w-2 bg-amber-200 dark:bg-amber-200" />
                    {(part2 || part3 || currentSliderValue) && (
                      <div
                        className="absolute top-0 left-0 bg-amber-200 dark:bg-amber-200 transition-all duration-300"
                        style={{
                          width: `${activeSlider === 3
                            ? getSpectrumPercentages(currentSliderValue || part3).a
                            : getSpectrumPercentages(currentSliderValue || part2).a}%`,
                          height: '8px'
                        }}
                      />
                    )}
                    <div className="flex items-start w-full">
                      <span className="w-[30px] flex-shrink-0">A)</span>
                      <span className="flex-grow text-left">{question.optionA}</span>
                    </div>
                  </div>
                  <div
                    className={cn(
                      "w-full text-left h-auto whitespace-pre-line p-4 justify-start transition-colors duration-200 relative overflow-hidden border rounded-md",
                      "dark:bg-gray-700 dark:text-gray-200"
                    )}
                  >
                    <div className="absolute left-0 top-0 bottom-0 w-2 bg-blue-100 dark:bg-blue-300" />
                    {(part2 || part3 || currentSliderValue) && (
                      <div
                        className="absolute top-0 left-0 bg-blue-100 dark:bg-blue-300 transition-all duration-300"
                        style={{
                          width: `${activeSlider === 3
                            ? getSpectrumPercentages(currentSliderValue || part3).b
                            : getSpectrumPercentages(currentSliderValue || part2).b}%`,
                          height: '8px'
                        }}
                      />
                    )}
                    <div className="flex items-start w-full">
                      <span className="w-[30px] flex-shrink-0">B)</span>
                      <span className="flex-grow text-left">{question.optionB}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Part 2 */}
              <div ref={part2Ref}>
                {/* <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center mb-4">
                        <h3 className="text-sm font-semibold text-foreground dark:text-gray-200 mr-2">
                          Please make a selection
                        </h3>
                        <Info className="w-4 h-4 text-muted-foreground" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{getTooltipText(currentSection, 2)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider> */}
                <CustomSlider
                  value={part2}
                  onChange={handleSliderMove}
                  onCommit={handleAnswerPart2}
                  disabled={isSubmitting}
                  isReset={isPart2Reset}
                  className="mt-2 mb-6"
                  previousPartAnswered={!!part1}
                  section={currentSection}
                />
              </div>

              {/* Part 3 */}
              <div ref={part3Ref} className={cn(!part2 && "opacity-50")}>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-sm font-semibold text-foreground dark:text-gray-200">
                    How about when you were younger?
                  </h3>
                  <Button
                    onClick={isPart3Locked ? handleUndoSkip : handlePart3Skip}
                    variant="outline"
                    disabled={isSubmitting || (!isPart3Locked && !part2)}
                    className={cn(
                      "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 font-bold",
                      !part2 && "opacity-50"
                    )}
                  >
                    {isPart3Locked ? "Undo Skip" : "Skip (I haven't changed)"}
                  </Button>
                </div>
                <CustomSlider
                  value={part3}
                  onChange={handleSliderMove}
                  onCommit={handleAnswerPart3}
                  disabled={isSubmitting || !part2}
                  locked={isPart3Locked}
                  isReset={isPart3Reset}
                  className="mt-2 mb-6"
                  previousPartAnswered={!!part2}
                  section={currentSection}
                />
              </div>

              {/* Optional Feedback Section */}
              <div
                ref={feedbackRef}
                className="transition-all duration-200"
                style={{
                  opacity: part3 ? 1 : 0.3,
                  pointerEvents: part3 ? 'auto' : 'none'
                }}
              >
                <h3 className="text-sm font-semibold text-foreground dark:text-gray-200 mb-4">
                  Optional: How easy was this question to understand and answer?
                </h3>
                <div className="flex gap-4 items-start">
                  <Button
                    onClick={() => {
                      handleQuestionFeedback("easy");
                      setDifficultyFeedback("");
                    }}
                    disabled={isSubmitting}
                    variant={questionFeedback === "easy" ? "default" : "outline"}
                    className={cn(
                      "min-w-[100px] w-fit transition-colors duration-200 font-bold",
                      questionFeedback !== "easy" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                    )}
                  >
                    <span>Easy</span>
                    <span role="img" aria-label="smiley face" className="text-xl ml-2">
                      😊
                    </span>
                  </Button>
                  <Button
                    onClick={() => handleQuestionFeedback("difficult")}
                    disabled={isSubmitting}
                    variant={questionFeedback === "difficult" ? "default" : "outline"}
                    className={cn(
                      "min-w-[100px] w-fit transition-colors duration-200 font-bold",
                      questionFeedback !== "difficult" && "dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                    )}
                  >
                    <span>Difficult</span>
                    <span role="img" aria-label="confused face" className="text-xl ml-2">
                      😕
                    </span>
                  </Button>
                </div>
                {questionFeedback === "difficult" && (
                  <div className="mt-4">
                    <Input
                      type="text"
                      value={difficultyFeedback}
                      onChange={(e) => setDifficultyFeedback(e.target.value)}
                      placeholder="How so?"
                      disabled={isSubmitting}
                      className="w-full dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600"
                    />
                  </div>
                )}
              </div>

              <Button
                ref={nextButtonRef}
                onClick={handleSubmit}
                disabled={!part2 || !part3 || isSubmitting}
                className={cn(
                  "w-full transition-all duration-200 font-bold text-white",
                  !part2 || !part3 || isSubmitting
                    ? "bg-blue-100/60 hover:bg-blue-100/70 disabled:bg-blue-100/40 dark:bg-blue-500/60 dark:hover:bg-blue-500/70 dark:disabled:bg-blue-500/40"
                    : "bg-blue-100 hover:bg-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600"
                )}
                variant="default"
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center gap-2">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    Submitting...
                  </div>
                ) : (
                  "Next"
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
      {DEV_TOOLS}
    </div>
  );
};

export default Survey;