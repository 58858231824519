import { VERSION } from './version'

interface EnvironmentConfig {
    apiBaseUrl: string
    environment: 'staging' | 'production'
    version: {
        buildNumber: string
        buildDate: string
        environment: string
    }
    basePath: string
    telemetryEnabled: boolean
    enableDebugPanel: boolean
}

const environments: Record<string, EnvironmentConfig> = {
    staging: {
        apiBaseUrl: 'https://fourcoins.me',
        environment: 'staging',
        version: VERSION,
        basePath: '/staging/',
        telemetryEnabled: true,
        enableDebugPanel: false
    },
    production: {
        apiBaseUrl: 'https://fourcoins.me',
        environment: 'production',
        version: VERSION,
        basePath: '/',
        telemetryEnabled: true,
        enableDebugPanel: false
    }
}

function getEnvironment(): EnvironmentConfig {
    // Log environment variables for debugging
    console.log('Environment variables:', {
        VITE_APP_ENV: import.meta.env.VITE_APP_ENV,
        DEV: import.meta.env.DEV,
        PROD: import.meta.env.PROD,
        MODE: import.meta.env.MODE
    });

    if (import.meta.env.DEV) {
        console.log('Using development environment config');
        return {
            apiBaseUrl: 'https://fourcoins.me',
            environment: 'staging',
            version: VERSION,
            basePath: '/staging/',
            telemetryEnabled: false,
            enableDebugPanel: true  // Enable debug panel in development
        };
    }

    const env = import.meta.env.VITE_APP_ENV || 'production';
    console.log('Selected environment:', env);

    const config = environments[env as 'staging' | 'production'];
    console.log('Using config:', config);

    return config;
}

// Export the function instead of its result
export function getConfig(): EnvironmentConfig {
    return getEnvironment();
}

// For backward compatibility and static config
export const config = getEnvironment(); 